<template>
  <div />
</template>
<script>
import { getWxCustDetail } from '_api/qycust';
import { geWxAgentConfig } from '_lib/enterprise-weChat';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'AssociatedError',
  data() {
    return {
      hide: false
    };
  },
  computed: {
    ...mapGetters(['externalUserid', 'userInfo'])
  },
  created() {
    this.init();
  },
  mounted() {
    // 获取字典
    this.getdictList();
    // 获取所属体系
    this.getOrgAlls(this.userInfo.postRoleResList[0].orgId);
  },
  methods: {
    ...mapActions(['getdictList', 'getOrgAlls']),
    async init() {
      await geWxAgentConfig();
      this.judgeIsThere();
    },
    judgeIsThere() {
      getWxCustDetail(this.externalUserid).then(res => {
        if (res.flag) {
          if (res.data.custView !== null) {
            this.toDetail();
          } else {
            this.toSelect();
          }
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    toDetail() {
      this.$router.push(
        {
          name: 'QycustDetail'
        }
      );
    },
    toSelect() {
      this.$router.push(
        {
          name: 'AssociatedPage'
        }
      );
    },
  }
};
</script>
