import { baseRequest } from './index';

/**
 * 根据当前人员和外部联系人id获取对应客户详情
 * @params externalUserid Number
 * @returns MenusInfo
*/
export const getWxCustDetail = (externalUserid) => baseRequest.post('/crmapp/custWxContact/getCustDetail', {externalUserid});

/**
 * 企业解绑
 * @params externalUserid Number
 * @params externalUserid custId
 * @returns MenusInfo
*/
export const unbindRelation = (externalUserid, custId) => baseRequest.post('/crmapp/custWxContact/unbindRelation', {externalUserid, custId});

/**
 * 绑定企业微信联系人和客户关系
 * @params externalUserid Number
 * @params externalUserid custId
 * @params externalUserid wxUserCode
 * @returns MenusInfo
*/
export const bindRelation = (externalUserid, custId, wxUserCode) => baseRequest.post('/crmapp/custWxContact/bindRelation', {externalUserid, custId, wxUserCode});
